/* =============================================================
   FONT DEFINITIONS
   ============================================================= */

/* Regular */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/assets/fonts/satoshi/Satoshi-Regular.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-Regular.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Italic */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/assets/fonts/satoshi/Satoshi-Italic.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-Italic.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Medium */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/assets/fonts/satoshi/Satoshi-Medium.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-Medium.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Medium Italic */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/assets/fonts/satoshi/Satoshi-MediumItalic.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-MediumItalic.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/assets/fonts/satoshi/Satoshi-Bold.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-Bold.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Bold Italic */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/assets/fonts/satoshi/Satoshi-BoldItalic.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-BoldItalic.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* Black */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/assets/fonts/satoshi/Satoshi-Black.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-Black.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* Black Italic */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/assets/fonts/satoshi/Satoshi-BlackItalic.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-BlackItalic.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

/* Variable Fonts */

/* Variable Regular */
@font-face {
  font-family: 'Satoshi-Variable';
  src: url('../public/assets/fonts/satoshi/Satoshi-Variable.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-Variable.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-style: normal;
  font-display: swap;
}

/* Variable Italic */
@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('../public/assets/fonts/satoshi/Satoshi-VariableItalic.woff2') format('woff2'),
       url('../public/assets/fonts/satoshi/Satoshi-VariableItalic.woff') format('woff'),
       url('../public/assets/fonts/satoshi/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-style: italic;
  font-display: swap;
}

/* =============================================================
   GENERAL STYLES
   ============================================================= */

.App {
  text-align: center;
  font-family: 'Satoshi', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #d9e0ee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* =============================================================
   TEXT STYLES
   ============================================================= */

/* Base Text Elements */
h1, h2, h3, h4, h5, h6, p, span {
  font-family: 'Satoshi', sans-serif;
  margin: 0;
  padding: 0;
}

/* Subtitle Styles */
.subtitle1 {
  font-size: 36px;
  font-weight: 600; /* Closest to 700 (Bold) */
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .subtitle1 {
    font-size: 23px;
  }
}

@media (max-width: 900px) {
  .subtitle1 {
    font-size: 20px;
  }
}

.subtitle2 {
  font-size: 34px;
  font-weight: 600; /* Closest to 700 (Bold) */
  line-height: 1.2;
}

/* Body Text Styles */
.body1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.body2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.bodyLarge {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
}

/* Heading Styles */
.h2 {
  font-size: 55px;
  letter-spacing: 3px;
  font-weight: 700; /* Bold */
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .h2 {
    font-size: 35px;
  }
}

@media (max-width: 900px) {
  .h2 {
    font-size: 34px;
  }
}

/* =============================================================
   UTILITY CLASSES
   ============================================================= */

/* Font Weight Utilities */
.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

/* Font Style Utilities */
.font-italic {
  font-style: italic;
}

/* =============================================================
   CUSTOM CLASSES
   ============================================================= */

/* Example: Applying Satoshi Medium */
.font-satoshi-medium {
  font-family: 'Satoshi';
  font-weight: 500;
}

/* Example: Applying Satoshi Bold */
.font-satoshi-bold {
  font-family: 'Satoshi';
  font-weight: 700;
}

/* Add this to your global CSS file */
.react-calendar {
  @apply w-full border-none bg-transparent;
}

.react-calendar__tile {
  @apply p-6 relative h-24;
}

.react-calendar__month-view__days__day {
  @apply text-gray-900;
}

.react-calendar__month-view__days__day--weekend {
  @apply text-red-600;
}

.react-calendar__tile--now {
  @apply bg-gray-50;
}

.react-calendar__tile--active {
  @apply bg-blue-600 text-white;
}

.react-calendar__navigation button {
  @apply text-gray-700 text-lg m-2;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  @apply bg-gray-50;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  @apply bg-gray-50;
}
