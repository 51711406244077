/* modal.css */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  /* Add the form styles here */
.form {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Add some space between form elements */
  border-radius: 8px;
}

.form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}